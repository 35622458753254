<!-- 申请专卖店 -->
<template>
  <div class="apply-container">
    <div class="fixed-title">
      <div class="img">
        <img src="http://th5.rolormd.com/7999/recruit/img/send/mmexport.png" height="32.5" width="32.5">
      </div>
      <div class="txt">更多便捷的支付方式，超多的钜惠活动</div>
      <div class="down" @click="onSubmit">立即打开</div>
    </div>
    <div class="main-title">开专卖店</div>
    <van-cell-group>
      <van-cell title="选择品类" class="brand-box">
        <van-radio-group v-model="brandId" direction="horizontal" checked-color="#a771ff">
          <van-radio :name="3">美妆</van-radio>
          <van-radio :name="4">大健康</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell :border="false">
        <div class="apply-prompt">
          需要完成一次梵洁诗或香蜜品牌12件自选商品混批采购订货，即可免费获得专卖店蜜蛋连锁店铺系统。
        </div>
      </van-cell>
      <van-cell class="pool-content">
        <van-checkbox v-model="agree" checked-color="#a771ff">我已阅读并同意<i @click.stop="showPoolLayer = true">《蜜蛋注册协议》</i></van-checkbox>
      </van-cell>
      <div class="operate">
        <van-button type="primary" color="#bd9bfb" round block @click="onSubmit">立即开启</van-button>
      </div>
    </van-cell-group>
    <mi-shop-right :brand-id="brandId" />
    <van-popup v-model="showPoolLayer" position="bottom" style="height:100%">
      <Protocol @close="closePoolLayer" />
    </van-popup>
  </div>
</template>

<script>
import Protocol from "../../components/Brand/Protocol"
import MiShopRight from "./component/MiShopRight"
import { openMdApp } from "../../utils/appUtil"
export default {
  name: 'applyGreenShare',
  components: { Protocol, MiShopRight },
  data() {
    return {
      brandId: 3,
      agree: false,
      brandList: [],
      showPoolLayer: false,
    }
  },
  created() {
    let { brandId } = this.$route.query;
    if (brandId) {
      this.brandId = brandId == '4' ? 4 : 3;
    }
  },
  methods: {
    closePoolLayer() {
      this.showPoolLayer = false;
    },
    //立即开店
    onSubmit() {
      let { shareUserId } = this.$route.query;
      openMdApp(this.baseHost, shareUserId);
    },

  }
}
</script>
<style lang='scss' scoped>
@import '../../assets/style/applyStore';
.van-popup {
  border-radius: 8px;
}
.main-title {
  padding-top: 20px;
}
.mi-manager {
  width: 300px;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 12px #999;

  .box {
    width: calc(100% - 58px);
    margin: auto;

    .head1 {
      overflow: hidden;
      margin-bottom: 10px;

      img {
        width: 50px;
        float: left;
        margin-right: 6px;
      }

      .name {
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        //line-height: 14px;
        color: #666666;
      }

      .name-na {
        //line-height: 14px;
        color: #999999;
      }

      &:after {
        content: '';
        clear: both;
      }
    }

    > img {
      max-width: 100%;
    }
  }

  .clickbtn {
    text-decoration: underline;
    color: #ff98a0;
    text-align: center;
    margin-top: 40px;

    span {
      font-size: 10px;
    }
  }

  p {
    color: #999;
    font-size: 12px;
    margin-bottom: 0;
    text-align: center;
    margin-top: 5px;
  }

  .icon-user {
    color: #e4877c;

    &:before {
      content: '\e929';
    }
  }

  .icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    color: #333333;
    padding: 12px;
    font-size: 12px;

    &:before {
      content: '\e9c5';
    }
  }
}
</style>